'use client';

import { DeviceDetector } from "@/lib/utils/device-util";
import React, { useContext, createContext, useState, ReactNode, useOptimistic } from "react";

export type ImageDisplayMode = "FullScreenMode" | "RankingMode" | "FollowPageScreenMode";
type SettingMenuProps = {
  isMeneuOpen: boolean;
};

type VisableMode = {
  isShow: boolean;
};
// アプリケーションで共通使用するコンテキストのプロパティ定義
type AppContextProps = {
  isMobile: boolean;
  settingMenuProps: SettingMenuProps;
  setSettingMenuProps: (settingMenuProps: SettingMenuProps) => void;
  visableMode: VisableMode;
  setVisableMode: (visableMode: VisableMode) => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  imageDisplayMode: ImageDisplayMode;
  sendingFollwoerIds: string[];
  setSendingFollwoerIds: (value: string[]) => void;
  setImageDisplayMode: (mode: ImageDisplayMode) => void;
  isSendingFollow: boolean,
  setIsSendingFollow: (value: boolean) => void;
};

// コンテキストを作成
const AppContext = createContext<AppContextProps>({
  settingMenuProps: {
    isMeneuOpen: false,
  },
  setSettingMenuProps: (settingMenuProps: SettingMenuProps) => { },
  visableMode: {
    isShow: true,
  },
  setVisableMode: (visableMode: VisableMode) => { },
  isMobile: false,
  isModalOpen: false,
  setIsModalOpen: (isModalOpen: React.SetStateAction<boolean>) => { },
  isUploading: false,
  setIsUploading: (isUploading: boolean) => { },
  loading: false,
  setLoading: (loading: boolean) => { },
  imageDisplayMode: "RankingMode",
  setImageDisplayMode: (mode: ImageDisplayMode) => { },
  sendingFollwoerIds: [],
  setSendingFollwoerIds: (value: string[]) => { },
  isSendingFollow: false,
  setIsSendingFollow: (value: boolean) => { },
});

// プロバイダーコンポーネント
export const AppContextProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [settingMenuProps, setSettingMenuProps] = useState<SettingMenuProps>({
    isMeneuOpen: false,
  });

  const [visableMode, setVisableMode] = useState<VisableMode>({
    isShow: true,
  });
  const [sendingFollwoerIds, setSendingFollwoerIds] = useState<string[]>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [imageDisplayMode, setImageDisplayMode] =
    useState<ImageDisplayMode>("RankingMode");

  const [isMobile] = useState(
    new DeviceDetector().isAndroid() ||
    new DeviceDetector().isiPhone ||
    new DeviceDetector().isiPad()
  );

  const [isSendingFollow, setIsSendingFollow] = useState(false);

  return (
    <AppContext.Provider
      value={{
        setSettingMenuProps,
        settingMenuProps,
        visableMode,
        setVisableMode,
        isModalOpen,
        setIsModalOpen,
        isUploading,
        setIsUploading,
        isMobile,
        loading,
        setLoading,
        imageDisplayMode,
        setImageDisplayMode,
        sendingFollwoerIds,
        setSendingFollwoerIds,
        isSendingFollow,
        setIsSendingFollow,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// コンテキストを使用するためのフック
export const useAppContext = (): AppContextProps =>
  useContext<AppContextProps>(AppContext);

export default AppContextProvider;
