import imageCompression from "browser-image-compression";

/**
 * 画像を圧縮する
 */
export const compressImage = async (file: File, fileSize: number): Promise<File> => {

	const options = {
		maxSizeMB: fileSize,
	}

	return imageCompression(file, options)
		.then(function (output) {
			const img = URL.createObjectURL(output)
			return output;
		})
		.catch(function (error) {
			throw new Error(error.message);
		})
}