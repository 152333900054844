export const URLConstants = {
  home: {
    pathname: "/",
  },
  user: {
    following: {
      pathname: "/user/following",
      new_posts: {
        pathname: "/user/following/new-posts",
      },
    },
    follower: {
      pathname: "/user/follower",
    },
    mypage: {
      pathname: "/user/mypage",
    },
    average: {
      pathname: "/user/average",
    },
    scoring: {
      history: {
        pathname: "/profile/scoring/history",
      },
    },
  },
  ranking: {
    pathname: "/ranking",
  },
  image: {
    pathname: "/image",
  },
};

// Laravel用のAPI
export const BackendURLContantas = {
  Auth: {
    SINGUP: {
      PATH_NAME: "/signup",
    },
    LOGOUT: {
      PATH_NAME: "/logout",
    },
    LOGIN: {
      PATH_NAME: "/login",
    },
  },
  IMAGE: {
    PATH_NAME: "/image/index",
    POST: {
      PATH_NAME: "/image/post",
    },
    FOLLOWING: {
      PATH_NAME: "/image/following",
    },
    DELETE: {
      PATH_NAME: "/image/delete",
    },
    SHOW: {
      PATH_NAME: "/image/show",
    },
    FIND: {
      PATH_NAME: "/image/find",
    },
    TOTAL: {
      PATH_NAME: "/image/total",
    },
    AVG: {
      PATH_NAME: "/image/avg",
    },
    LATEST: {
      PATH_NAME: "/image/latest",
    },
    RANKING: {
      HIGHEST: {
        PATH_NAME: "/image/ranking/highest",
      },
      LOWEST: {
        PATH_NAME: "/image/ranking/lowest",
      },
      NOT_SCORED: {
        PATH_NAME: "/image/ranking/not-scored",
      },
      LATEST: {
        PATH_NAME: "/image/ranking/latest",
      },
      FOLLOWING: {
        PATH_NAME: "/image/ranking/following",
      },
    },
  },
  USER: {
    PATH_NAME: "/user",
    POST: {
      PATH_NAME: "/user/post",
    },
    UPDATE: {
      HIDE_NAME: {
        PATH_NAME: "/user/update/hide-name",
      },
      PATH_NAME: "/user/update",
    },
    EXISTS_NAME: {
      PATH_NAME: "/user/exists-name",
    },
    GET: {
      PATH_NAME: "/user/get",
    },
    ICON: {
      PATH_NAME: "/user/icon",
      UPDATE: {
        PATH_NAME: "/user/icon-update",
      },
    },
    TOTAL_AND_AVG: {
      PATH_NAME: "user/total-and-avg",
    },
    TOTAL: {
      PATH_NAME: "/user/total",
    },
    AVG: {
      PATH_NAME: "/user/avg",
    },
    IMAGE: {
      PATH_NAME: "/user/image",
      LATEST: {
        PATH_NAME: "/user/image/latest",
      },
      HIGHEST: {
        PATH_NAME: "/user/image/highest",
      },
      LOWEST: {
        PATH_NAME: "/user/image/lowest",
      },
      NOT_SCORED: {
        PATH_NAME: "/user/image/not-scored",
      },
    },
    HISTORY: {
      PATH_NAME: "/user/history/desc",
    },
    FOLLOWED: {
      PATH_NAME: "/user/followed",
    },
    FOLLOWING: {
      PATH_NAME: "/user/following",
    },
    FOLLOW_USERS: {
      PATH_NAME: "/user/follow-users",
    },
    FOLLOWING_USERS: {
      PATH_NAME: "/user/following-users",
    },
  },
  SCORE: {
    PATH_NAME: "/score",
  },

  NOTIFICATION: {
    SCORING_HISTORY: {
      PATH_NAME: "notify/scoring-history",
      READ: {
        PATH_NAME: "notify/scoring-history/read",
      },
    },
    NEW_POSTED_IMAGE: {
      PATH_NAME: "notify/new-posted-image",
      READ: {
        PATH_NAME: "notify/new-posted-image/read",
      },
    },
  },
};
