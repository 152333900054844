import { adjustImageOrientation, removeMetadata } from "@/lib/file/image";
import { compressImage } from "@/lib/file/image/compressor";
import {
  convertCanvasToFile,
  convertHeicToJpg,
  convertToWebp,
} from "@/lib/file/image/converter";

/**
 * 画像ファイルをアップロード可能な形式に変換する
 * @param file - 処理する画像ファイル
 * @returns - 処理後のFileオブジェクトを返すPromise
 */
export const convertToUploadableImageFile = async (
  file: File
): Promise<File> => {
  let convertedFile = file;

  // 変換処理
  convertedFile = await convertHeicToJpg(convertedFile);
  convertedFile = await convertToWebp(convertedFile);

  // 一意なファイル名を作成
  const extension = convertedFile.name.split(".").pop(); // ファイル拡張子を取得
  const extensionLength =
    extension === undefined
      ? convertedFile.name.length + 1
      : extension.length + 1;
  const newFileName = `${convertedFile.name.slice(0, -extensionLength)}.webp`;
  const timestamp = new Date().getTime();
  const uniqueFilename = `${timestamp}_${newFileName}`;

  // 画像の向きを調整
  const canvas = await adjustImageOrientation(convertedFile);

  convertedFile = await convertCanvasToFile(
    canvas,
    uniqueFilename,
    "image/webp"
  );

  const fileSizeInMB = convertedFile.size / (1024 * 1024);

  // 1MB以上
  if (1 < fileSizeInMB) {
    convertedFile = await compressImage(convertedFile, 1);
  }
  // 2MB以上
  else if (2 < fileSizeInMB) {
    convertedFile = await compressImage(convertedFile, 2);
  }

  // メタデータを削除
  convertedFile = await removeMetadata(convertedFile);

  return convertedFile;
};

export default convertToUploadableImageFile;
