'use client';

import { adjustImageOrientation } from ".";
import { isHeic, isHeif } from "./validator";

/**
 * HEIF/HEICをJPGに変換する
 */
export const convertHeicToJpg = async (file: File): Promise<File> => {
	if (!(isHeic(file) || isHeif(file))) {
		return file;
	}

	const { default: heic2any } = await import("heic2any");
	const outputBlob = await heic2any({ blob: file, toType: "image/jpg" });

	if (Array.isArray(outputBlob)) {
		throw new Error("Error converting HEIC/HEIF to JPG");
	} else {
		return new File([outputBlob], file.name, { type: "image/jpg" });
	}
}

/**
 * webpに変換する
 */
export const convertToWebp = async (file: File): Promise<File> => {

	let convertedFile = await convertHeicToJpg(file);
	const canvas = await adjustImageOrientation(convertedFile);
	const blob = await convertCanvasToBlob(canvas, "image/webp");
	const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".webp";

	return new File([blob], newFileName, { type: "image/webp" });
}


/**
 * キャンバスをBlobに変換する
 */
export const convertCanvasToBlob = (canvas: HTMLCanvasElement, mimeType: string): Promise<Blob> => {
	return new Promise((resolve) => {
		canvas.toBlob((blob) => {
			if (blob) {
				resolve(blob);
			}
		}, mimeType);
	});
};

/**
 * キャンバスをFileオブジェクトに変換する
 * @param canvas - 変換するキャンバス要素
 * @param fileName - 元のファイル名
 * @param mimeType - 変換後のMIMEタイプ（デフォルトは"image/webp"）
 * @returns - 変換後のFileオブジェクトを返すPromise
 */
export const convertCanvasToFile = async (canvas: HTMLCanvasElement, fileName: string, mimeType: string = "image/webp"): Promise<File> => {
	const blob: Blob = await new Promise((resolve) => {
		canvas.toBlob((blob) => {
			if (blob) {
				resolve(blob);
			}
		}, mimeType);
	});

	return new File([blob], fileName, { type: mimeType });
};



