
export class BrowserTypeChecker {
	private userAgent: string;

	constructor () {
		this.userAgent = navigator.userAgent;
	}

	public isSafari (): boolean {
		return /^((?!chrome|android).)*safari/i.test(this.userAgent);
	}

	public isChrome (): boolean {
		return /chrome/i.test(this.userAgent) && !/edge|opr/i.test(this.userAgent);
	}

	public isFirefox (): boolean {
		return /firefox/i.test(this.userAgent);
	}

	public isEdge (): boolean {
		return /edg/i.test(this.userAgent);
	}

	public isOpera (): boolean {
		return /opr/i.test(this.userAgent);
	}
}