/**
 * v0 by Vercel.
 * 画像アイコン
 */
export default function ImageIcon (props: any) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
			<defs>
				<style>
					{`
            .cls-1 { fill: none; }
            .cls-1, .cls-2 { stroke-width: 0px; }
            .cls-2 { fill: #fff; }
          `}
				</style>
			</defs>
			<g>
				<path className="cls-2" d="M0,13.5v73c0,.552.448,1,1,1h98c.552,0,1-.448,1-1V13.5c0-.552-.448-1-1-1H1c-.552,0-1,.448-1,1ZM72.933,51.929l-8.622,10.915c-.4.507-1.169.507-1.569,0l-21.69-27.457c-.4-.507-1.169-.507-1.569,0l-29.697,37.594c-.588.744-1.785.328-1.785-.62V21.5c0-.552.448-1,1-1h82c.552,0,1,.448,1,1v49.701c0,.948-1.197,1.364-1.785.62l-15.713-19.892c-.4-.507-1.169-.507-1.569,0Z" />
				<circle className="cls-2" cx="75.344" cy="35.343" r="7.831" />
			</g>
			<rect className="cls-1" width="100" height="100" />
		</svg>
	);
}