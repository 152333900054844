import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatNumber(num: number): string {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 10000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
};

// タイムアウトを設定する関数
export function timeout(ms: number) {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('Timeout'));
    }, ms);
  });
};

export function isIntegerString(str: string) {
  return /^\d+$/.test(str);
}

export function trimSpaces(str: string): string {
  return str.replace(/^\s+|\s+$/g, '').replace(/^[\u3000]+|[\u3000]+$/g, '');
}